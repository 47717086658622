<!--  -->
<template>
  <div class="swiperTop">
    <div class="swiperBorder">
      <swiper :options="swiperOptions" class="swiperCon" ref="swiper1" id="swiperCon">
        <swiper-slide v-for="(item,index) in wallpaperList" :key="index">
          <video :src="item.video_url" :poster="item.img_url" muted="muted" loop="loop" x5-video-player-type="h5"></video>
          <img :src="item.img_url">
        </swiper-slide>
      </swiper>
    </div>
    <div class="phone">
      <transition name="fade" v-for="(item,index) in wallpaperList" :key="index">
        <img :src="item.app_img_url"  :class="[select == index?'active':'']">
      </transition>
    </div>
    <div class="xiao" v-if="wallpaperList.length <= 6">
      <ul>
        <li v-for="(item,index) in wallpaperList" :key="index" :class="[select == index?'active':'']" @click="changeSelect(index)">
          <img :src="item.img_url">
        </li>
      </ul>
    </div>
    <div class="swiperXiao" v-else>
      <swiper :options="swiperOptions2" class="swiperX" ref="swiper2">
        <swiper-slide v-for="(item,index) in wallpaperList" :key="index">
          <img :src="item.img_url">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    wallpaperList: null,
  },
  data() {
    let self = this;
    return {
      videoTime: null,
      select: 0,
      swiperOptions: {
        loop: true,
        initialSlide: 0,
        autoplay: 5000,
        autoplayDisableOnInteraction: false,
        // loopAdditionalSlides: 999,
        onSlideChangeStart: function (swiper) {
          self.changeSelectEnd(swiper.realIndex);
          self.onSildeChange(swiper.activeIndex);
          // self.playPhone(swiper.realIndex);
        },
        onSlideChangeEnd: function (swiper) {
          // console.log("onSlideChangeEnd");
          // self.onSildeChange(swiper.activeLoopIndex);
        },
      },
      swiperOptions2: {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 8,
        initialSlide: 4,
        centeredSlides: true,
        slideToClickedSlide: true,
        loopAdditionalSlides: 999,
        touchRatio: 0, // 触摸系数为0，即不能拖动
        onClick: function (swiper) {
          self.changeSelect2(swiper.realIndex);
        },
      },
    };
  },
  computed: {
    swiperone() {
      return this.$refs.swiper1.swiper;
    },
    swipertow() {
      return this.$refs.swiper2 && this.$refs.swiper2.swiper;
    },
  },
  watch: {
    select() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperMou();
    });
  },
  methods: {
    changeSelectEnd(index) {
      if (this.select != index) {
        this.select = index;
        if (this.swipertow) {
          this.swipertow.slideTo(index + this.swipertow.loopedSlides);
        }
      }
    },
    changeSelect(index) {
      console.log(index);
      if (this.select != index) {
        this.select = index;
        this.swiperone.slideTo(index + this.swiperone.loopedSlides);
      }
    },
    changeSelect2(index) {
      this.select = index;
      this.swiperone.slideTo(index + this.swiperone.loopedSlides);
    },
    onSildeChange(current) {
      clearTimeout(this.videoTime);
      this.videoTime = null;
      this.videoTime = setTimeout(() => {
        const el = this.$el;
        const videos = el.querySelectorAll(".swiperBorder video");
        videos.forEach((item) => item.pause());
        videos[current].currentTime = 0;
        videos[current].play();
      }, 500);
    },
    swiperMou() {
      let self = this;
      // 获取你想要添加悬停效果的元素
      var element = document.getElementById("swiperCon");
      // 添加 mouseenter 事件监听器
      element.addEventListener("mouseenter", function () {
        // 鼠标进入元素时的操作
        self.$refs.swiper1.swiper.stopAutoplay();
      });
      // 添加 mouseleave 事件监听器
      element.addEventListener("mouseleave", function () {
        // 鼠标离开元素时的操作
        self.$refs.swiper1.swiper.startAutoplay();
      });
    },
    playPhone(index) {
      for (let i = 0; i < this.wallpaperList.length; i++) {
        if (this.$refs["video_" + i]) {
          this.$refs["video_" + i][0].currentTime = 0;
          this.$refs["video_" + i][0].pause();
        }
      }
      this.$refs["video_" + index][0].play();
    },
  },
};
</script>

<style lang='scss' scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.swiperTop {
  margin: 0 auto;
  top: 115px;
  width: 910px;
  position: absolute;
  left: 50%;
  margin-left: -379px;
  .swiperBorder {
    width: 910px;
    height: 512px;
    position: relative;
    border-radius: 20px;
    &::before {
      content: "";
      position: absolute;
      top: -4px;
      right: -4px;
      bottom: -4px;
      left: -4px;
      z-index: 1;
      background: url("../../assets/images/bd01/border.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .phone {
    position: absolute;
    width: 220px;
    height: 391px;
    z-index: 9;
    top: 138px;
    left: -162px;
    border-radius: 20px;
    overflow: hidden;
    pointer-events: none;
    background: #000;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: url("../../assets/images/bd01/phoneBg.png") no-repeat;
      background-size: 100% 100%;
      z-index: 9;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      position: absolute;
      left: 0;
      top: 0;
      &.active {
        z-index: 2;
      }
    }
  }
  .swiperCon {
    border-radius: 20px;
    overflow: hidden;
    .swiper-slide {
      height: 512px !important;
      background: #000;
      position: relative;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      position: relative;
      z-index: 2;
    }
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      top: 0;
      left: 0;
    }
  }
  .xiao {
    position: absolute;
    bottom: 18px;
    right: 20px;
    z-index: 8;
    ul {
      display: flex;
      li {
        width: 99px;
        height: 56px;
        border: 2px solid rgba(255, 255, 255, 0.8);
        position: relative;
        margin-left: 8px;
        border-radius: 8px;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          pointer-events: none;
          left: -2px;
          top: -2px;
          right: -2px;
          bottom: -2px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 8px;
        }
        &:hover {
          border: 2px solid rgba(255, 255, 255, 1);
          &::before {
            background: rgba(0, 0, 0, 0.3);
          }
        }
        &.active {
          border: 2px solid #fff;
          &::before {
            display: none;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 8px;
        }
      }
    }
  }
  .swiperXiao {
    position: absolute;
    bottom: 18px;
    right: 20px;
    z-index: 8;
    .swiperX {
      width: 560px;
      .swiper-slide {
        width: 100px;
        border-radius: 8px;
        border: 2px solid rgba(255, 255, 255, 0.8);
        overflow: hidden;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          pointer-events: none;
          left: -2px;
          top: -2px;
          right: -2px;
          bottom: -2px;
          background: rgba(0, 0, 0, 0.7);
        }
        &.swiper-slide-active {
          border: 2px solid #fff;
          cursor: default;
          &::before {
            display: none;
          }
        }
        &:hover {
          border: 2px solid rgba(255,255,255,1);
          &::before {
            background: rgba(0, 0, 0, 0.3);
          }
        }
      }
      img {
        width: 100px;
        height: 56px;
      }
    }
  }
}
</style>