<!--  -->
<template>
  <div class="bgImg">
    <section class="part-album position-relative pb-5" id="partAlbum">
      <div class="d-inline-flex album-box1">
        <img class="album-img" src="../../assets/images/bd01/top/1-1.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-2.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-3.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-4.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-5.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-6.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-7.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-8.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-9.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-1.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-2.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-3.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-4.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-5.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-6.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-7.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-8.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/1-9.jpg" alt="album-img">
      </div>
      <div class="d-inline-flex album-box2">
        <img class="album-img" src="../../assets/images/bd01/top/2-1.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-2.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-3.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-4.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-5.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-6.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-7.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-8.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-9.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-1.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-2.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-3.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-4.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-5.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-6.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-7.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-8.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/2-9.jpg" alt="album-img">
      </div>
      <!-- <div class="d-inline-flex album-box3">
        <img class="album-img" src="../../assets/images/bd01/top/3-1.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-2.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-3.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-4.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-5.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-6.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-7.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-8.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-9.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-1.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-2.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-3.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-4.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-5.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-6.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-7.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-8.jpg" alt="album-img">
        <img class="album-img" src="../../assets/images/bd01/top/3-9.jpg" alt="album-img">
      </div> -->
      <div class="pt-lg-5 pb-xxl-3"></div>
    </section>
  </div>

</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
@keyframes rolling {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
@keyframes rollingReverse {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}
.bgImg{
  overflow-x: hidden;
  position: relative;
  height: 874px;
}
#partAlbum {
  background-color: #000;
  z-index: 1;
  padding-top: 20px;
  .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    .album-img {
      margin-top: 0;
      opacity: 0.4;
      margin: 9px;
      transition: all 1s;
    }
  }
  .album-box1 {
    animation: rolling 132s linear infinite;
  }
  .album-box2 {
    transform: translateX(50%);
    animation: rollingReverse 222s linear infinite;
  }
  .album-box3 {
    animation: rolling 342s linear infinite;
  }
  &::after {
    content: "";
    width: 100%;
    height: 63%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      359.71deg,
      #100000 33.27%,
      rgba(16, 0, 0, 0) 99.75%
    );
    opacity: 0.9;
    pointer-events: none;
    pointer-events: none;
  }
}
</style>